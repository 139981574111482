<template>
  <div class="login_container">
    <video poster="@/assets/images/login/video-cover.jpeg" loop autoplay muted></video>
    <div class="login_box">
      <div class="avatar_title">SAAS后台管理</div>
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="请输入登录账号"></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input v-model="loginForm.password" prefix-icon="el-icon-monitor" type="password" placeholder="请输入登录密码"></el-input>
        </el-form-item>

        <el-form-item class="btns">
          <el-button type="primary" @click="submitForm" :loading="isLogin">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios' // 引入axios

export default {
  data() {
    return {
      loginForm: {
        username: 'user',
        password: '123456',
        // captcha: '',
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
        ],
      },
      isLogin: false,
    }
  },
  created() {},
  mounted() {},

  methods: {
    submitForm() {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) return false
        this.login()
      })
    },

    async login() {
      try {
        this.isLogin = true
        const res = await this.$API.login(this.loginForm)
        this.isLogin = false
        if (res.code !== 200) return this.$message.info(res.error)
        window.localStorage.setItem('Token', res.data.token)
        this.menuList()
      } catch (error) {
        this.isLogin = false
      }
    },
    async menuList() {
      const res = await this.$API.menu.get()
      if (res.status != 200) return ''
      this.$store.dispatch('menuList', res.data)
      this.$router.replace('/')
    },

    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.login_container {
  height: 100vh;
  width: 100%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}
.avatar_title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
  color: rgb(8, 167, 241);
}
</style>
